import { computed, defineComponent, ref, watch, } from 'vue';
import BaseSelect from '@/components/BaseSelect.vue';
import BaseTable from '@/components/BaseTable/index.vue';
import BaseTableCol from '@/components/BaseTable/BaseTableCol';
import Pager from '@/components/Pager.vue';
import BaseButton from '@/components/BaseButton.vue';
import { useRoute } from 'vue-router';
import usePager from '@/utils/usePager';
import Backend from '@/core/Services/Backend';
import { useI18n } from 'vue-i18n';
import useDialog from '@/utils/useDialog';
import Dialog from '@/components/Dialog.vue';
import { getTableIndex } from '@/core/utils';
import formatTime from '@/core/utils/formatTime';
import Approve from './Approve.vue';
export default defineComponent({
    name: 'TaskDetail',
    components: {
        BaseSelect,
        BaseTable,
        BaseTableCol,
        Pager,
        BaseButton,
        Dialog,
        Approve,
    },
    setup() {
        const { t, locale } = useI18n();
        const route = useRoute();
        const { id, chain } = route.query;
        const taskStatus = ref('');
        const taskStatusOption = computed(() => [
            {
                label: t('all'),
                value: '',
            },
            {
                label: t('review_pending'),
                value: '0',
            },
            {
                label: t('passed'),
                value: '1',
            },
            {
                label: t('refused'),
                value: '2',
            },
        ]);
        const { list: taskTableData, total: recordTotal, page, loadCurrentPage, size, } = usePager((pageIndex, pageSize, cancelToken) => Backend.getTaskDetailList(pageIndex, pageSize, cancelToken, String(id), taskStatus.value));
        // 监听taskStatus变化时回到第一页
        watch(taskStatus, () => {
            page.value = 1;
            loadCurrentPage();
        });
        const { isVisible: isShowDialog, closeDialog, showDialog, selectedTarget: approveData, } = useDialog();
        // 展示审核弹框
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const showApprove = (row) => {
            showDialog({
                id: Number(id),
                userId: row.userId,
                taskId: row.id,
                createTime: row.createTime,
                address: row.address,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                approvelRequired: row.airdropCondition.find((i) => i.language === locale.value)?.airdropCondition || row.airdropCondition[0],
            });
        };
        // 下载全部列表
        const saveForm = () => {
            Backend.exportAirdrop(Number(id));
        };
        return {
            t,
            id,
            chain,
            taskStatus,
            taskStatusOption,
            taskTableData,
            page,
            size,
            recordTotal,
            loadCurrentPage,
            isShowDialog,
            closeDialog,
            showDialog,
            showApprove,
            approveData,
            getTableIndex,
            formatTime,
            saveForm,
        };
    },
});
