import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
export default defineComponent({
    name: 'DialogContainer',
    props: {
        title: {
            type: String,
            required: true,
        },
        confirmDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        showBtns: {
            type: Boolean,
            requried: false,
            default: true,
        },
    },
    components: { BaseButton },
    emits: ['confirm', 'cancel'],
    setup(props, { emit }) {
        const confirm = () => {
            emit('confirm');
        };
        const cancel = () => {
            emit('cancel');
        };
        return {
            confirm,
            cancel,
        };
    },
});
