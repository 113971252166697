import BaseButton from '@/components/BaseButton.vue';
import Backend from '@/core/Services/Backend';
import formatTime from '@/core/utils/formatTime';
import { defineComponent } from 'vue';
import DialogContainer from '@/components/DialogContainer.vue';
import copy from 'copy-to-clipboard';
import { useI18n } from 'vue-i18n';
import Toast from '@/components/Toast';
import { openExplorerForAddress } from '@/core/utils/openExplorer';
import Dialog from '@/components/Dialog.vue';
import useDialog from '@/utils/useDialog';
export default defineComponent({
    name: 'Approve',
    props: {
        id: {
            // 这条记录的id
            type: Number,
            required: true,
        },
        taskId: {
            // userifo接口返回的单条空投任务的id
            type: Number,
            required: true,
        },
        userId: {
            // 用户ontid
            type: String,
            required: true,
        },
        chain: {
            type: String,
            required: true,
        },
        createTime: {
            type: Number,
            required: true,
        },
        address: {
            type: String,
            required: true,
        },
        approvelRequired: {
            type: Object,
            required: true,
        },
        closeDialog: {
            type: Function,
            require: false,
        },
        callback: {
            type: Function,
            require: false,
        },
    },
    components: {
        BaseButton,
        DialogContainer,
        Dialog,
    },
    setup(props) {
        const { t } = useI18n();
        const approve = (isPass) => {
            Backend.approveUserTask(props.id, props.taskId, isPass ? 1 : 2).then(() => {
                Toast.success(t('review_success'));
                if (props.closeDialog) {
                    props.closeDialog();
                }
                if (props.callback) {
                    props.callback();
                }
            });
        };
        const copyAddress = () => {
            copy(props.address);
            Toast.success(t('copy_success'));
        };
        const toExplorer = () => {
            openExplorerForAddress(props.chain, props.address);
        };
        // 查看大图功能
        const { isVisible: isShowLarger, showDialog: showLarger, closeDialog: closeLarger, selectedTarget: selectedLarger, } = useDialog();
        return {
            t,
            approve,
            formatTime,
            copyAddress,
            toExplorer,
            isShowLarger,
            showLarger,
            closeLarger,
            selectedLarger,
        };
    },
});
